import ContactPageLayoutI from "./ContactPageLayoutI.type";
import * as styles from "./ContactPageLayout.module.scss";
import ContactForm from "../../Scripts/Scripts/ContactForm";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactPageLayout = ({ data }: ContactPageLayoutI) => {
  return (
    <div className={styles.contactPageWrapper}>
      <div className={styles.contactPageHero}>
        <h1 className={styles.contactHeroTitleWrapper}>
          <span className={styles.black}>
            {data.heroTitleStart}
            <br />
          </span>
          <span className={styles.green}>{data.heroTitleEnd}</span>
        </h1>
        <p className={styles.contactPageSubText}>
          {data.heroSubText.heroSubText}
        </p>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.bulletPointWrapper}>
          <div className={styles.bullet}>
            <GatsbyImage
              image={data.infoBulletOneIcon.gatsbyImageData}
              alt={data.infoBulletOneIcon.title}
              class={styles.gatsbyImageContainer}
            />
            <p className={styles.infoBulletContent}>
              {data.infoBulletOneText.infoBulletOneText}
            </p>
          </div>
          <div className={styles.bullet}>
            <GatsbyImage
              image={data.infoBulletTwoIcon.gatsbyImageData}
              alt={data.infoBulletTwoIcon.title}
              class={styles.gatsbyImageContainer}
            />
            <p className={styles.infoBulletContent}>
              {data.infoBulletTwoText.infoBulletTwoText}
            </p>
          </div>
          <div className={styles.bullet}>
            <GatsbyImage
              image={data.infoBulletThreeIcon.gatsbyImageData}
              alt={data.infoBulletThreeIcon.title}
              class={styles.gatsbyImageContainer}
            />
            <p className={styles.infoBulletContent}>
              {data.infoBulletThreeText.infoBulletThreeText}
            </p>
          </div>
        </div>
        <div className={styles.contactFormWrapper}>
          <ContactForm
            targetId="contactForm"
            formId={data.formId}
          ></ContactForm>
        </div>
      </div>
    </div>
  );
};
export default ContactPageLayout;
