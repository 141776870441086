// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var contactPageWrapper = "ContactPageLayout-module--contactPageWrapper--KThMJ";
export var contactPageHero = "ContactPageLayout-module--contactPageHero--1kgVY";
export var contactHeroTitleWrapper = "ContactPageLayout-module--contactHeroTitleWrapper--1OcUg";
export var black = "ContactPageLayout-module--black--2tMnT";
export var green = "ContactPageLayout-module--green--3uazz";
export var contactPageSubText = "ContactPageLayout-module--contactPageSubText--iL5qM";
export var contentWrapper = "ContactPageLayout-module--contentWrapper--6ZbX0";
export var bulletPointWrapper = "ContactPageLayout-module--bulletPointWrapper--7tvW2";
export var gatsbyImageContainer = "ContactPageLayout-module--gatsbyImageContainer--2rbR_";
export var bullet = "ContactPageLayout-module--bullet--FGMwp";
export var infoBulletContent = "ContactPageLayout-module--infoBulletContent--1qBds";
export var gatsbyImageWrapper = "ContactPageLayout-module--gatsby-image-wrapper--BSqih";
export var contactFormWrapper = "ContactPageLayout-module--contactFormWrapper--1c8DW";