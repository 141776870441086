import { useEffect, useState } from "react";
import ContactPageLayout from "../components/ContactPage/ContactPageLayout/ContactPageLayout";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import Layout from "../components/Layout/Layout";
import { useCookie } from "../utils/getCookie";

const contactPage = ({ pageContext }: any) => {
  const data = pageContext.data.contentfulContactPage;
  const dataSEO = {
    metaTitle: data?.metaTitle,
    metaDescription: data?.metaDescription?.metaDescription || "",
    keywords: data?.metaTitle,
    preventIndexing: false,
    alternateHrefLink: true,
  };

  const [showCookiesConsent, setShowCookiesConsent] = useState(false);

  useEffect(() => {
    const trackCookie = useCookie("gatsby-gdpr-tracking");
    const tagCookie = useCookie("via-gdpr-google-tagmanager");
    if (!trackCookie || !tagCookie) {
      setShowCookiesConsent(true);
    }
  }, []);

  const {
    footer_menu,
    header_menu,
    logo,
    cookieBarDescription,
    cookieBarTitle,
    cookiePolicyLink,
    footer,
  } = pageContext.globalSettings;
  return (
    <div>
      <Layout
        footer_menu={footer_menu}
        header_menu={header_menu}
        footer={footer}
        logo={logo}
        background={true}
        page_background={true}
        dataSEO={dataSEO}
        countryPage={false}
        blog={true}
        url={pageContext.url}
        white={true}
        no_menu={false}
        menuCTALink={"#contactForm"}
      >
        <ContactPageLayout data={data}></ContactPageLayout>
        {showCookiesConsent && (
          <CookiesBanner
            dismiss={() => setShowCookiesConsent(false)}
            cookieBarDescription={cookieBarDescription}
            cookiePolicyLink={cookiePolicyLink}
            cookieBarTitle={cookieBarTitle}
          />
        )}
      </Layout>
    </div>
  );
};

export default contactPage;
