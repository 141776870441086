import React, { useEffect } from "react";
import ContactFormI from "./ContactFormI.type";

const ContactForm = ({ targetId, formId }: ContactFormI) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "8466054",
          formId: `${formId || "fd80d2ff-e902-4c88-82b5-88e8eb3e4d19"}`,
          target: `#${targetId}`,
          version: "V2_PRERELEASE",
        });
      }
    });
  }, []);
  return <div id={targetId}></div>;
};

export default ContactForm;
